'use client'

import { motion, useInView } from "framer-motion"
import { useRef } from "react"
import "./css/accreditation.css"

const accreditationData = [
  {
    id: 1,
    title: "SOC 2 Type 2",
    image: "./soc.png",
    description: "Service Organization Control 2 Type 2 Certified"
  },
  {
    id: 2,
    title: "ISO 27001",
    image: "./iso.png",
    description: "Information Security Management System Certified"
  }
]

export default function Accreditation() {
  const sectionRef = useRef(null)
  const isInView = useInView(sectionRef, { once: false, threshold: 0.1 })

  const sectionAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut", staggerChildren: 0.2 },
    },
  }

  const logoAnimation = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1, 
      transition: { duration: 0.5, ease: "easeOut" } 
    },
  }

  return (
    <motion.section
      ref={sectionRef}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={sectionAnimation}
      className="accreditation-section"
    >
      <div className="accreditation-header">
        <h1>
          <span className="title-normal">Our </span>
          <span className="title-highlight">Accreditations</span>
        </h1>

        <p className="footer-text">
  Proudly accredited to deliver trusted and certified security testing solutions for your digital assets.
</p>

       
      </div>

      <motion.div 
        variants={sectionAnimation}
        className="accreditation-container"
      >
        {accreditationData.map((item) => (
          <motion.div
            key={item.id}
            variants={logoAnimation}
            className="accreditation-card"
          >
            <div className="card-overlay" />
            <div className="card-content">
              <img
                src={item.image || "/placeholder.svg"}
                alt={item.title}
                className="card-image"
              />
            </div>
          </motion.div>
        ))}
      </motion.div>

      {/* Add the new text below */}
      <motion.div 
        className="accreditation-footer"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut", delay: 0.3 }}
      >
        
      </motion.div>
    </motion.section>
  )
}
